import React from "react";
import { graphql } from "gatsby";
import { useIntl, Link } from "gatsby-plugin-react-intl";
import Layout from "../components/layout";
import Seo from "../components/seo";

const PageRecruit = ({ data, location }) => {
  const intl = useIntl();
  const posts = data.allContentfulRecruit.edges;

  return (
    <Layout location={location} mode="bls">
      <Seo
        title={
          intl.locale === "en"
            ? `Careers at Cyberdyne`
            : `Karriere bei Cyberdyne`
        }
        description={
          intl.locale === "en"
            ? `Join our  team  and work for a growing company using revolutionary medical technology. This is where individual envision  gather together, committing to the values that lead to better society.`
            : `Werden Sie Teil unseres Teams, einer wachsenden Firma, die neuartige medizinische Technologien nutzt. Unsere Visionen verpflichten sich einer besseren Gesellschaft.`
        }
        ogImage="https://images.ctfassets.net/t2ifj85iosf3/30spG8dUj9zO9eMGHPOw1R/2deb893cdd00e8966349420b14e6c20e/E179.jpg?w=1200&h=630&fit=fill"
      />
      <section className="sect bg-bge">
        <div className="container container-newslist">
          <h1 className="h1">
            {intl.locale === "en" ? `Join our team!` : `Trete unserem Team bei!`}
          </h1>

          <div className="news-card-wrap flex-row flex-wrap mx-n2">
            {posts && posts.map(({ node: post }) => {
              return (
                <div key={post.slug} className="flex-column-4 p-2">
                  <div className="news-card">
                    <h2 className="title">{post.title}</h2>
                    <p className="excerpt">
                      {post.content.childMarkdownRemark.excerpt}
                    </p>
                    <div className="news-link">
                      <Link to={`/recruit/${post.slug}`} className="">
                        {intl.locale === "en" ? `Read` : `Lesen`}
                      </Link>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default PageRecruit;

export const pageQuery = graphql`
  query ($language: String) {
    allContentfulRecruit(
      limit: 30
      filter: {
        slug: { ne: "do-not-delete-sample" }
        node_locale: { eq: $language }
      }
    ) {
      edges {
        node {
          primrerStandort
          slug
          title
          arbeitszeit
          bevorzugterArbeitsort
          bewerbung {
            bewerbung
          }
          content {
            content
            childMarkdownRemark {
              excerpt
            }
          }
          erfahrungsniveau
          jobtyp
          unternehmen
        }
      }
    }
  }
`;
